<template>
  <div class="car-confirmation">
    <Header :isHeader="true" :back="true" />
    <Wrapper classContainer="" :ismain="true">
      <Title titleStyle="title" :text="translateString('plateNotFound')" />
      <p class="sm-text">
        {{ translateString("fieldsToFoundCar") }}
      </p>
      <SelectCustom
        id="brand"
        dataSearch="model"
        :label="translateString('selectBrand')"
        :defaultSelect="translateString('brand')"
        :class="brands.length > 0 ? 'select-input' : 'select-input disabled'"
        :values="brands"
        @selectValue="selectValue"
        :selectedValue="carNF.brand"
        :noAll="true"
      />
      <SelectCustom
        id="model"
        dataSearch="body"
        :label="translateString('selectModel')"
        :defaultSelect="translateString('model')"
        :class="models.length > 0 ? 'select-input' : 'select-input disabled'"
        :values="models"
        @selectValue="selectValue"
        :selectedValue="carNF.model"
        :noAll="true"
      />
      <SelectCustom
        id="body"
        dataSearch="fuel"
        :label="translateString('bodyType')"
        :defaultSelect="translateString('body')"
        :class="bodies.length > 0 ? 'select-input' : 'select-input disabled'"
        :values="bodies"
        @selectValue="selectValue"
        :selectedValue="carNF.body"
        :noAll="true"
      />
      <SelectCustom
        id="fuel"
        dataSearch="version"
        :label="translateString('selectFuel')"
        :defaultSelect="translateString('fuel')"
        :class="fuels.length > 0 ? 'select-input' : 'select-input disabled'"
        :values="fuels"
        @selectValue="selectValue"
        :selectedValue="carNF.fuel"
        :noAll="true"
      />
      <SelectCustom
        id="version"
        dataSearch="token"
        :label="translateString('selectVersion')"
        :defaultSelect="translateString('version')"
        :class="versions.length > 0 ? 'select-input' : 'select-input disabled'"
        :values="versions"
        @selectValue="selectValue"
        :selectedValue="carNF.version"
        :noAll="true"
      />
      <Input
        id="plate"
        :label="translateString('plate')"
        :placeholder="translateString('plate')"
        class="text-input"
        @updateInput="updateInput($event, 'plate')"
        :errorMsg="translateString('insertValidPlate')"
        :value="carPlate"
      />
      <Input
        id="kms"
        :label="translateString('insertKms')"
        :placeholder="translateString('kms')"
        class="text-input"
        @updateInput="updateInput($event, 'kms')"
        :errorMsg="translateString('insertValidValueWNumber')"
        :value="carKms"
      />
      <template v-slot:footer>
        <Footer>
          <Button
            :label="translateString('continue')"
            :btnClass="ctaBtn"
            @action="next()"
          />
        </Footer>
      </template>
    </Wrapper>
    <Modal :msg="callError" :isActive="callError != ''" />
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
import Footer from "@/components/Footer/Footer.vue";
import Header from "@/components/Header/Header.vue";
import Input from "@/components/Inputs/Input.vue";
import Modal from "@/components/Modal/Modal.vue";
import SelectCustom from "@/components/Inputs/SelectCustom.vue";
import Title from "@/components/Title/Title.vue";
import Wrapper from "@/components/Wrapper/Wrapper.vue";

import { validateInputs } from "@/functions/validations.js";
import { translateString } from "@/functions/functions.js";

export default {
  name: "PlateNotFound",
  components: {
    Button,
    Footer,
    Header,
    Input,
    Modal,
    SelectCustom,
    Title,
    Wrapper,
  },
  data() {
    return {
      ctaBtn: "disabled ",
      brand: "",
      model: "",
      body: "",
      fuel: "",
      version: "",
    };
  },
  computed: {
    finalError() {
      return this.$store.state.finalError;
    },
    carInfoReady() {
      return this.$store.state.carComboNFReady;
    },
    callError() {
      return this.$store.state.callError;
    },
    carPlate() {
      return this.$store.state.carPlate;
    },
    carKms() {
      return this.$store.state.carKms;
    },
    brands() {
      return this.$store.state.brandNF;
    },
    models() {
      return this.$store.state.modelNF;
    },
    bodies() {
      return this.$store.state.bodyNF;
    },
    fuels() {
      return this.$store.state.fuelNF;
    },
    versions() {
      return this.$store.state.versionNF;
    },
    carNF() {
      return this.$store.state.carNF;
    },
  },
  created() {
    this.$store.dispatch("selectOption", {
      selectedValue: "finalError",
      value: false,
    });
    this.$store.dispatch("getAll", "brand");
    this.$store.dispatch("clearSelectedInfo", "Homepage");
    this.carNF.plate = this.carPlate ? this.carPlate : "";
    this.carNF.kms = this.carKms ? this.carKms : "";
  },
  methods: {
    translateString,
    updateInput(value, field) {
      this.carNF[field] = value;
    },
    selectValue(e) {
      const object = e.id;
      const selected = e.value;
      const dataSearch = e.target.dataset.search;

      this.$store.dispatch("selectOption", {
        selectedValue: object,
        value: selected,
        obj: "carNF",
      });

      this.$store.dispatch("getAll", dataSearch);
    },
    next() {
      if (validateInputs(["kms"]).length == 0) {
        this.$store.dispatch("getCarByToken");
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: true,
        });
        this.$store.dispatch("selectOption", {
          selectedValue: "carPlate",
          value: this.carNF.plate,
        });
        this.$store.dispatch("selectOption", {
          selectedValue: "carKms",
          value: this.carNF.kms.replace(/\s+/g, ""),
        });
      }
    },
  },
  watch: {
    carNF: {
      handler(carNF) {
        if (Object.values(carNF).some((x) => x === null || x === "")) {
          this.ctaBtn = "disabled ";
        } else {
          this.ctaBtn = "";
        }
      },
      deep: true,
    },
    carInfoReady() {
      if (this.carInfoReady == true) {
        if (this.$store.state.carCombo.length > 1) {
          this.$router.push({ name: "CarIdentification" });
        } else {
          this.$router.push({ name: "CarConfirmation" });
          if(this.$store.state.carCombo.length == 1) {
            this.$store.dispatch("selectOption", {
              selectedValue: "carComboSelected",
              value: {
                comboLabel: this.$store.state.carCombo[0].typeVehicleDesc,
                comboId: this.$store.state.carCombo[0].typeVehicleId,
                comboSelectedByUser: false,
              },
            });
          }
        }
        this.$store.dispatch("selectOption", {
          selectedValue: "isLoading",
          value: false,
        });
      }
    },
    finalError() {
      if (this.finalError == true) {
        this.$router.push({ name: "ErrorForm" });
      }
    },
  },
};
</script>
